import * as React from 'react';

import './Article.scss';
import {Article as ArticleType} from './index';

const Article = ({title, image, imageAlt, body}: ArticleType) => (
  <div className={'article'}>
    <div className={'title'}>
      <h2 className={'title__text'}>{title}</h2>
    </div>

    <div className={'body'}>
      <div className={'body__desc'}>
        {/*<p className={'body__desc__text'}>{body}</p>*/}
        <article
          dangerouslySetInnerHTML={{__html: body}}
          itemProp="articleBody"
          className={'article__body__text'}
        />
      </div>
    </div>

    <div className={'article__image'}>
      <img src={image} alt={imageAlt} className={'article__image__element'} />
    </div>
  </div>
);

export default Article;
