import {Link, graphql} from 'gatsby';
import * as React from 'react';
import {ReactNode} from 'react';

import Article from '../components/Article';
import Button from '../components/Button';
import Layout from '../components/layout';
import ArticleHeader from '../components/pages/ArticleHeader';
import Seo from '../components/seo';
import './BlogPost.scss';

const BlogPostTemplate = ({data}) => {
  const {previous, next} = data;
  const post = data.markdownRemark;
  const article = {...post.frontmatter, image: post.frontmatter.image.publicURL, body: post.html};

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <ArticleHeader />
      <Article {...article} />

      <ArticleNavigation next={next} previous={previous} />
    </Layout>
  );
};

interface Navigation {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

type ArticleNavigationProps = {
  next: Navigation;
  previous: Navigation;
};

const AltButton = ({children, props}: {children: ReactNode; props?: any}) => (
  <Button {...props} className={'alt_button'}>
    {children}
  </Button>
);

const ArticleNavigation = ({next, previous}: ArticleNavigationProps) => (
  <nav className="nav">
    <div className={'nav__list'}>
      {/* TODO remove link in favour of button press for nav action */}
      {previous && (
        <AltButton>
          <Link to={previous.fields.slug} rel="prev" className={'alt_button__text'}>
            ← {previous.frontmatter.title}
          </Link>
        </AltButton>
      )}
      {next && (
        <AltButton>
          <Link to={next.fields.slug} rel="next" className={'alt_button__text'}>
            {next.frontmatter.title} →
          </Link>
        </AltButton>
      )}
    </div>
  </nav>
);

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: {eq: $id}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          publicURL
        }
      }
    }
    previous: markdownRemark(id: {eq: $previousPostId}) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: {eq: $nextPostId}) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
